import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BgEmergencyHead from '@/assets/img/bg_emergency_head.png';
import BgOneOff1 from '@/assets/img/bg_object_oneoff_01.png';
import IconEmergency1 from '@/assets/img/icons/icon_emergency_01@2x.png';
import IconEmergency2 from '@/assets/img/icons/icon_emergency_02@2x.png';
import IconEmergency3 from '@/assets/img/icons/icon_emergency_03@2x.png';
import IconWhiteArrow from '@/assets/img/icons/icon_white_arrow_24x25@2x.png';
import ImageEm1 from '@/assets/img/img_emergency_process_01@2x.png';
import ImageEm2 from '@/assets/img/img_emergency_process_02@2x.png';
import ImageEm3 from '@/assets/img/img_emergency_process_03@2x.png';
import ImageOnOff1 from '@/assets/img/img_oneoff_01@2x.png';
import ImageOnOff2 from '@/assets/img/img_oneoff_02@2x.png';
import ImageOnOff3 from '@/assets/img/img_oneoff_03@2x.png';
import ImageOnOff4 from '@/assets/img/img_oneoff_05@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import { SwiperContainer } from '@/components/Card/RoundThumbnailCard';
import Container from '@/components/Container';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import { MoreViewLink, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  SUPPORT_CATEGORY_ONCE_MEMORY,
  SUPPORT_CATEGORY_ONCE_WORLD,
  SUPPORT_TERM_REGULAR,
  SUPPORT_TERM_ONCE,
  SUPPORT_CATEGORY_REGULAR_WORLD,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { graphql, navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar } from 'swiper';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { GtmButtonDonate } from '@/components/Button.gtm';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';

SwiperCore.use([Navigation, Scrollbar, A11y]);

const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 72px;
  }
`;
const TitleContainer = styled.div`
  position: relative;
`;
const OneOffItem = styled.div`
  border: 1px solid #e5e6e8;
  background: #fff;
  display: flex;
  padding: 32px 24px;
  ${breakpoint(`mobile`)} {
    display: block;
    padding: 16px 16px 24px 16px;
  }
`;
const OneOffList = styled.div`
  ${OneOffItem} + ${OneOffItem} {
    margin-top: 32px;
  }
`;
const OneOffThumb = styled.div`
  width: 390px;
  img {
    max-width: 100%;
  }
  ${breakpoint(1024)} {
    width: 42%;
  }
  ${breakpoint(`mobile`)} {
    width: 100%;
  }
`;
const OneOffFlex = styled.div`
  width: 680px;
  height: 217px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  ${breakpoint(1024)} {
    width: 58%;
    height: 21.3vw;
  }
  ${breakpoint(`mobile`)} {
    width: 100%;
    height: auto;
    display: block;
  }
`;
const OneOffText = styled.div`
  padding-left: 32px;
  width: 100%;
  letter-spacing: -0.8px;
  p {
    margin-top: 16px;
  }
  ${breakpoint(`mobile`)} {
    padding-left: 0;
    margin-top: 16px;
    p {
      br {
        display: none;
      }
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 32px;
  width: 352px;

  ${Section} {
    width: 100%;
  }

  ${breakpoint(`mobile`)} {
    padding-left: 0;
    margin-top: 32px;
    width: 100%;
    button {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
const ObjectSection = styled(Section)`
  position: relative;
  background: #374ea2;
  overflow: hidden;

  ${SectionHeader} {
    z-index: 1;
    position: relative;
  }
  ${OneOffList} {
    position: relative;
    z-index: 0;
    &::before {
      content: '';
      display: block;
      width: 414px;
      height: 414px;
      background: url(${BgOneOff1}) no-repeat center center;
      background-size: 100%;
      position: absolute;
      right: -207px;
      top: -207px;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 35.69vw;
        height: 35.69vw;
        right: -17.84vw;
        top: -17.84vw;
      }
    }
  }
  .swiper-scrollbar {
    position: absolute;
    height: 4px;
    width: 100%;
    height: 4px;
    bottom: 0;
    &::before {
      content: '';
      background: #e2e3e3;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    .swiper-scrollbar-drag {
      height: 4px;
      background: #1cabe2;
    }
  }
`;
const ObjectSectionHead = styled.div`
  width: 100%;
  height: 170px;
  background: url(${BgEmergencyHead}) no-repeat center center;
  background-size: auto 100%;
`;

const SectionHowto = styled(Section)`
  background: #374ea2;
  overflow: hidden;
  padding-top: 240px;

  ${Container} {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 1400px;
      height: 1400px;
      background: #3162af;
      border-radius: 100%;
      position: absolute;
      left: -522px;
      bottom: -444px;
    }

    ${SectionHeader} {
      position: relative;
      z-index: 1;
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 120px;

    ${Container} {
      position: relative;

      &::before {
        left: -580px;
        bottom: -690px;
      }
    }
    ${SectionHeader} {
      padding-bottom: 48px;
    }
  }
`;

const EmergencyDetail = styled.div`
  display: flex;
  justify-content: space-between;

  ${SwiperContainer} {
    padding-bottom: 120px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .swiper-slide {
    /* width: 33.33%; */
    width: 352px;
    padding: 0 16px;
    margin: 0 !important;

    dt {
      margin-bottom: 64px;
      text-align: center;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url(${IconWhiteArrow}) center no-repeat;
        background-size: cover;
        position: absolute;
        right: -28px;
        top: 50%;
        transform: translate(0, -50%);
      }

      ${Tit} {
        display: inline-block;
        padding-left: 80px;
        position: relative;
        line-height: 80px;

        &::before {
          content: '';
          display: block;
          width: 80px;
          height: 80px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url(${IconEmergency1});
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    &:nth-child(2) {
      dt {
        ${Tit} {
          &::before {
            background-image: url(${IconEmergency2});
          }
        }
      }
    }
    &:nth-child(3) {
      dt {
        :before {
          display: none;
        }
        ${Tit} {
          &::before {
            background-image: url(${IconEmergency3});
          }
        }
      }
    }

    dd {
      .dec-wrap {
        overflow: hidden;
        float: right;
        border-radius: 0 0 40px 0;
        width: 284px;
        margin: -55px 0 0 0;
        background: #fff;
        position: relative;
        z-index: 1;
        ${NotiBox} {
          margin: 0;
          padding: 30px 0 30px 30px;
        }
        ${NotiItem} {
          font-size: 16px;
          font-weight: bold;
          &::after {
            top: 12px;
          }
          & + ${NotiItem} {
            margin-top: 8px;
          }
        }
        ${breakpoint(`mobile`)} {
          width: 100%;
          margin: 0;
          ${NotiBox} {
            padding: 20px;
          }
          ${NotiItem} {
            font-size: 14px;
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${SwiperContainer} {
      margin-left: -12px;
      margin-right: -12px;
    }

    .swiper-slide {
      width: 258px;
      padding: 0 12px;

      dt {
        margin-bottom: 26px;

        &::before {
          background-size: 15px auto;
        }

        ${Tit} {
          padding-left: 56px;
          line-height: 56px;

          &::before {
            width: 56px;
            height: 56px;
          }
        }
      }

      dd {
        .dec-wrap {
          width: 100%;
          margin-top: 0;

          ${NotiBox} {
            padding: 20px 26px 22px;
          }
          ${NotiItem} {
            font-size: 14px;
            &::after {
              top: 12px;
            }
          }
          ${breakpoint(`mobile`)} {
            width: 100%;
            margin: 0;
            ${NotiBox} {
              padding: 20px;
            }
            ${NotiItem} {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
const NavigationButton = styled.div`
  padding: 24px 48px 24px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  ${breakpoint(`mobile`)} {
    font-size: 16px;
    padding: 20px 40px 20px 0;
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

export interface FaqData {
  id: string | number;
  title?: string;
  contents?: string;
  link: string;
}

const OneOffIndex: React.FC<PageProps<GatsbyTypes.OneoffFaqPageQuery>> = ({
  location,
  data,
}) => {
  const [faqLists, setFaqLists] = useState<FaqData[]>([]);
  const [newsLists, setNews] = useState<CampaignData[]>([]);

  const loadFaqLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['260'],
        optData2: '101',
        pagePerCount: 5,
        statusCode: '1',
      });
      const oneoffFaq = data as any;
      setFaqLists(
        oneoffFaq.map((faq: BoardVo) => ({
          id: faq.boardIndexNumber,
          title: faq.subject,
          contents: faq.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadNewsLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: [
          'A001',
          'A002',
          'A003',
          'A004',
          'A005',
          'A006',
          'A007',
          'A010',
          'A011',
          'A015',
          'A019',
          'A020',
          'A021',
          'A029',
        ],
      });
      const articles = data as any;
      setNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadFaqLists();
    loadNewsLists();
  }, [loadFaqLists, loadNewsLists]);

  const getSupportPageUrl = (supportCategory: string, solicitCode?: string) => {
    const searchParams = new URLSearchParams({
      supportTerm: SUPPORT_TERM_ONCE,
      supportCategory,
    });
    if (solicitCode) {
      searchParams.set(`solicitcode`, solicitCode);
    }
    return `/donate/?${searchParams.toString()}`;
  };

  const getPledgeSupportPageUrl = (
    supportCategory: string,
    solicitCode?: string,
  ) => {
    const searchParams = new URLSearchParams({
      supportTerm: SUPPORT_TERM_REGULAR,
      supportCategory,
    });
    if (solicitCode) {
      searchParams.set(`solicitcode`, solicitCode);
    }
    return `/donate/?${searchParams.toString()}`;
  };

  return (
    <LayoutWithTitle
      location={location}
      title="일시후원"
      description="for every child, a donation"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  어린이를 위한 기부의 첫 걸음
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <OneOffList>
            <OneOffItem>
              <OneOffThumb>
                <img src={ImageOnOff1} alt="" />
              </OneOffThumb>
              <OneOffFlex>
                <OneOffText>
                  <h3>
                    <Tit size="s4" color="sky">
                      세계 어린이 돕기
                    </Tit>
                  </h3>
                  <p>
                    한 번의 후원도 도움이 시급한 전 세계 어린이를&nbsp;
                    <br />
                    즉각적으로 지원한다는 점에서 아주 소중합니다.
                  </p>
                </OneOffText>
                <ButtonContainer>
                  <GtmButtonDonate
                    type="button"
                    text="후원하기"
                    color="yellow"
                    title="일시후원-세계어린이돕기"
                    full
                    goto={getSupportPageUrl(SUPPORT_CATEGORY_ONCE_WORLD)}
                    gtmInfo={{
                      ...SelectItemDefaultData,
                      itemId: SUPPORT_CATEGORY_ONCE_WORLD,
                      itemName: '세계 어린이 돕기',
                      itemCategory: SUPPORT_TERM_ONCE,
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  />
                </ButtonContainer>
              </OneOffFlex>
            </OneOffItem>
            <OneOffItem>
              <OneOffThumb>
                <img src={ImageOnOff2} alt="" />
              </OneOffThumb>
              <OneOffFlex>
                <OneOffText>
                  <h3>
                    <Tit size="s4" color="sky">
                      생명을 구하는 선물
                    </Tit>
                  </h3>
                  <p>
                    유니세프의 구호 물품을 직접 후원하시면 위급한 순서대로
                    {` `}
                    <br />
                    어린이들에게 물품이 전달됩니다.
                  </p>
                </OneOffText>
                <ButtonContainer>
                  <GtmButtonDonate
                    type="button"
                    text="후원 가능한 물품 보기"
                    color="yellow"
                    title="일시후원-생명을구하는선물"
                    full
                    goto="/involve/individual/ig"
                    gtmInfo={{
                      ...SelectItemDefaultData,
                      campaignName: 'InspiredGift',
                      campaignPagenum: 'D302',
                      promotionName: 'InspiredGift',
                      promotionId: 'D302',
                      itemId: '',
                      itemName: '',
                      itemCategory: SUPPORT_TERM_ONCE,
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  />
                </ButtonContainer>
              </OneOffFlex>
            </OneOffItem>
            <OneOffItem>
              <OneOffThumb>
                <img src={ImageOnOff3} alt="" />
              </OneOffThumb>
              <OneOffFlex>
                <OneOffText>
                  <h3>
                    <Tit size="s4" color="sky">
                      기념 기부
                    </Tit>
                  </h3>
                  <p>
                    살면서 누구나 맞이하는 수많은 특별한 날 <br />
                    유니세프와 함께 더 특별하고 따뜻하게 만들어 보세요.
                  </p>
                </OneOffText>
                <ButtonContainer>
                  <GtmButtonDonate
                    type="button"
                    text="후원하기"
                    color="yellow"
                    title="일시후원-기념 기부"
                    full
                    goto={getSupportPageUrl(SUPPORT_CATEGORY_ONCE_MEMORY)}
                    gtmInfo={{
                      ...SelectItemDefaultData,
                      itemId: SUPPORT_CATEGORY_ONCE_MEMORY,
                      itemName: '기념 기부',
                      itemCategory: SUPPORT_TERM_ONCE,
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  />
                </ButtonContainer>
              </OneOffFlex>
            </OneOffItem>
            <OneOffItem>
              <OneOffThumb>
                <img src={ImageOnOff4} alt="" />
              </OneOffThumb>
              <OneOffFlex>
                <OneOffText>
                  <h3>
                    <Tit size="s4" color="sky">
                      정기후원
                    </Tit>
                  </h3>
                  <p>
                    같은 금액이라도 매월 일정 금액을 꾸준히 후원한다면
                    <br /> 어린이에게 안정적으로 더 큰 도움을 줄 수 있습니다.
                  </p>
                </OneOffText>
                <ButtonContainer>
                  <GtmButtonDonate
                    type="button"
                    text="정기후원하기"
                    color="yellow"
                    title="일시후원-정기후원하기"
                    full
                    goto={getPledgeSupportPageUrl(
                      SUPPORT_CATEGORY_REGULAR_WORLD,
                    )}
                    gtmInfo={{
                      ...SelectItemDefaultData,
                      itemId: SUPPORT_CATEGORY_REGULAR_WORLD,
                      itemName: '세계 어린이 돕기',
                      itemCategory: SUPPORT_TERM_REGULAR,
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  />
                </ButtonContainer>
              </OneOffFlex>
            </OneOffItem>
          </OneOffList>
        </Container>
      </SectionTop>
      <ObjectSectionHead />

      {data.allEmergencySupport.nodes.length !== 0 ? (
        <ObjectSection>
          <Container>
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1" color="white">
                  긴급구호! 후원자님의 손길이 <br />
                  급히 필요합니다{` `}
                </Tit>
              </h2>
            </SectionHeader>
            <OneOffList>
              {data.allEmergencySupport.nodes.map((emergency) => (
                <OneOffItem key={emergency.id}>
                  <OneOffThumb>
                    <img src={emergency.attGrpNoTn} alt="" />
                  </OneOffThumb>
                  <OneOffFlex>
                    <OneOffText>
                      <h3>
                        <Tit size="s4" color="sky">
                          {emergency.homepageTitle}
                        </Tit>
                      </h3>
                      <p>{emergency.supportSubTitle}</p>
                    </OneOffText>
                    <ButtonContainer>
                      <GtmButtonDonate
                        type="button"
                        text="후원하기"
                        color="yellow"
                        title={emergency.homepageTitle}
                        full
                        goto={getSupportPageUrl(
                          emergency.supportCode,
                          emergency.solicitCode,
                        )}
                        gtmInfo={{
                          ...SelectItemDefaultData,
                          itemId: emergency.supportCode,
                          itemName: emergency.homepageTitle,
                          itemCategory: SUPPORT_TERM_ONCE,
                        }}
                        onClickPreEvent={() => {
                          // TODO onClick before call gtm
                          // alert('click');
                        }}
                      />
                    </ButtonContainer>
                  </OneOffFlex>
                </OneOffItem>
              ))}
            </OneOffList>
          </Container>
        </ObjectSection>
      ) : (
        ``
      )}

      <SectionHowto>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1" color="white">
                유니세프 긴급구호, <br />
                이렇게 이루어 집니다
              </Tit>
            </h2>
          </SectionHeader>
          <EmergencyDetail>
            <SwiperContainer
              slidesPerView="auto"
              navigation
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <dl className="card">
                  <dt className="first">
                    <Tit size="s3-1" color="white">
                      긴급구호상황 발생
                    </Tit>
                  </dt>
                  <dd>
                    <div className="img-wrap">
                      <img src={ImageEm1} alt="" />
                    </div>
                    <div className="dec-wrap">
                      <NotiBox className="FormGroup last">
                        <NotiList>
                          <NotiItem>현지 긴급구호팀 조직 생성 및 파견</NotiItem>
                          <NotiItem>피해상황 신속 파악</NotiItem>
                          <NotiItem>구호물품 공급 전략 수립</NotiItem>
                        </NotiList>
                      </NotiBox>
                    </div>
                  </dd>
                </dl>
              </SwiperSlide>
              <SwiperSlide>
                <dl className="card">
                  <dt>
                    <Tit size="s3-1" color="white">
                      1차 긴급구호 지원
                    </Tit>
                  </dt>
                  <dd>
                    <div className="img-wrap">
                      <img src={ImageEm2} alt="" />
                    </div>
                    <div className="dec-wrap">
                      <NotiBox className="FormGroup last">
                        <NotiList>
                          <NotiItem>1차 긴급구호물품 전달</NotiItem>
                          <NotiItem>항공 운송수단 확보</NotiItem>
                          <NotiItem>현지 정부 및 단체의 물품조달 지원</NotiItem>
                        </NotiList>
                      </NotiBox>
                    </div>
                  </dd>
                </dl>
              </SwiperSlide>
              <SwiperSlide>
                <dl className="card">
                  <dt>
                    <Tit size="s3-1" color="white">
                      추가 긴급구호 지원
                    </Tit>
                  </dt>
                  <dd>
                    <div className="img-wrap">
                      <img src={ImageEm3} alt="" />
                    </div>
                    <div className="dec-wrap">
                      <NotiBox className="FormGroup last">
                        <NotiList>
                          <NotiItem>2차 긴급구호물품 전달</NotiItem>
                          <NotiItem>임시 구호시설 설치 및 서비스 지원</NotiItem>
                          <NotiItem>향후 긴급구호 계획 수립</NotiItem>
                        </NotiList>
                      </NotiBox>
                    </div>
                  </dd>
                </dl>
              </SwiperSlide>
            </SwiperContainer>
          </EmergencyDetail>
        </Container>
      </SectionHowto>
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">
                  후원자님의 소중한 {` `}
                  <br
                    css={`
                      ${breakpoint(640)} {
                        display: none;
                      }
                    `}
                  />
                  기부금으로 현지에서는
                </Tit>
              </h2>
              <MoreViewLink to="/what-we-do/news" className="more">
                <span>더 보기</span>
              </MoreViewLink>
            </div>
          </SectionHeader>
          {newsLists.length > 0 && (
            <RelativeBoardCard
              newslist={newsLists}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </Section>
      <Section className="by-sub-main-layout">
        <Container>
          <TitleContainer>
            <SectionHeader className="with-desc">
              <div className="flex-middle">
                <h2>
                  <Tit size="s1">
                    일시후원 & 기부 관한 자주 묻는 질문
                  </Tit>
                </h2>
                <MoreViewLink
                  to="/faq?category=1&hashTag=101#categorySection"
                  className="more"
                >
                  <span>더 보기</span>
                </MoreViewLink>
              </div>
            </SectionHeader>
          </TitleContainer>
          {faqLists.length > 0 && (
            <AccordionComponent initialKey={faqLists[0].id}>
              {faqLists.map((faq) => (
                <AccordionItem
                  key={faq.id}
                  toggleKey={faq.id}
                  renderToggle={(onClick) => (
                    <NavigationButton
                      onClick={onClick}
                      className="accordion-opener"
                    >
                      {faq.title}
                    </NavigationButton>
                  )}
                >
                  <AccordionBody
                    dangerouslySetInnerHTML={{ __html: faq.contents }}
                  />
                </AccordionItem>
              ))}
            </AccordionComponent>
          )}
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export const query = graphql`
  query OneoffFaqPage {
    allEmergencySupport {
      nodes {
        id
        supportCode
        solicitCode
        homepageTitle
        supportSubTitle
        attGrpNoTn
      }
    }
  }
`;

export default OneOffIndex;
